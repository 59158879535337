import React from 'react';
import clsx from 'clsx';
import { useStyles } from './styles';
import withWidth from '@material-ui/core/withWidth';
import IconButton from "@material-ui/core/IconButton";
import Hidden from "@material-ui/core/Hidden";
import MenuIcon from '@material-ui/icons/Menu';
import { useSelector, useDispatch } from 'react-redux';
import { handleSidebarDrawerToggleStateAction } from '../store/actions';


const AppBar = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch()
  const { appState } = useSelector(state => state)
  const { sidebarDrawerToggleState } = appState

  const handleDrawerOpen = () => {
    dispatch(handleSidebarDrawerToggleStateAction(true))
  };

  return (
    <Hidden mdUp>
      {!sidebarDrawerToggleState ?
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={handleDrawerOpen}
          edge="start"
          className={clsx(classes.menuButton, sidebarDrawerToggleState && classes.hide)}
        >
          <MenuIcon />
        </IconButton> : true
      }
    </Hidden>
  );
}

export default withWidth()(AppBar)


