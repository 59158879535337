const types = {
    CLEAR_STATE: "CLEAR_STATE",
    HANDLE_SIDEBAR_NAVIGATION_STATE: "HANDLE_SIDEBAR_NAVIGATION_STATE",
    HANDLE_SIDEBAR_DRAWER_TOGGLE_STATE: "HANDLE_SIDEBAR_DRAWER_TOGGLE_STATE",

    API_LOGIN_INITIATE: "API_LOGIN_INITIATE",
    API_LOGIN_SUCCESS: "API_LOGIN_SUCCESS",

    API_LOGOUT_INITIATE: "API_LOGOUT_INITIATE",
    API_LOGOUT_SUCCESS: "API_LOGOUT_SUCCESS",

    API_FORGOT_PASSWORD_INITIATE: "API_FORGOT_PASSWORD_INITIATE",
    API_FORGOT_PASSWORD_SUCCESS: "API_FORGOT_PASSWORD_SUCCESS",

    API_CHANGE_PASSWORD_INITIATE: "API_CHANGE_PASSWORD_INITIATE",
    API_CHANGE_PASSWORD_SUCCESS: "API_CHANGE_PASSWORD_SUCCESS",

    API_RESET_PASSWORD_LINK_INITIATE: "API_RESET_PASSWORD_LINK_INITIATE",
    API_RESET_PASSWORD_LINK_SUCCESS: "API_RESET_PASSWORD_LINK_SUCCESS",

    API_RESET_PASSWORD_INITIATE: "API_RESET_PASSWORD_INITIATE",
    API_RESET_PASSWORD_SUCCESS: "API_RESET_PASSWORD_SUCCESS",

    API_AUTHENTICATION_FAILED: "API_AUTHENTICATION_FAILED",


    UPDATE_LOADER: "UPDATE_LOADER",

    API_GET_FOLLOWERS_SUCCESS : "API_GET_FOLLOWERS_SUCCESS",
    API_GET_FOLLOWERS_INITIATE : "API_GET_FOLLOWERS_INITIATE",

    API_GET_POST_SUCCESS : "API_GET_POST_SUCCESS",
    API_GET_POST_INITIATE  : "API_GET_FOLLOWERS_INITIATE",

    API_DELETE_POST_SUCCESS : "API_DELETE_POST_SUCCESS",
    API_DELETE_POST_INITIATE  : "API_DELETE_POST_INITIATE",
    API_DELETE_POST_FAILURE : "API_DELETE_POST_FAILURE",


    API_CREATE_POST_INITIATE: 'API_CREATE_POST_INITIATE',
    API_CREATE_POST_SUCCESS : "API_CREATE_POST_SUCCESS",
    API_CREATE_POST_FAILURE : "API_CREATE_POST_FAILURE",


    API_GET_DETAIL_SUCCESS : "API_GET_DETAIL_SUCCESS",
    API_GET_DETAIL_INITIATE : "API_GET_DETAIL_INITIATE",

    API_REMOVEFOLLWER_SUCCESS : "API_REMOVEFOLLWER_SUCCESS",
    API_REMOVEFOLLWER_INITIATE  : "API_REMOVEFOLLWER_INITIATE",
    API_REMOVEFOLLWER_FAILURE : "API_REMOVEFOLLWER_FAILURE",


}
export default types

