import types from '../types';

export const handleClearStateAction = () => ({
    type: types.CLEAR_STATE,
});

export const handleNavigationStateAction = (index, isNested) => ({
    type: types.HANDLE_SIDEBAR_NAVIGATION_STATE,
    index,
    isNested,
});

export const handleSidebarDrawerToggleStateAction = (toggler) => ({
    type: types.HANDLE_SIDEBAR_DRAWER_TOGGLE_STATE,
    toggler,
});

export const loginInitiate = (payload, history) => ({ type: types.API_LOGIN_INITIATE, payload, history })

export const logoutInitiate = (history) => ({ type: types.API_LOGOUT_INITIATE, history })

export const forgotPasswordInitiate = (payload, history) => ({ type: types.API_FORGOT_PASSWORD_INITIATE, payload, history })

export const changePasswordInitiate = (payload, history) => ({ type: types.API_CHANGE_PASSWORD_INITIATE, payload, history })

export const resetPasswordLinkInitiate = (payload) => ({ type: types.API_RESET_PASSWORD_LINK_INITIATE, payload })

export const resetPasswordInitiate = (payload, history) => ({ type: types.API_RESET_PASSWORD_INITIATE, payload, history })

export const globalLoader = (payload) => ({ type: types.UPDATE_LOADER, payload })

export const getallfollower = () => ({ type: types.API_GET_FOLLOWERS_INITIATE })

export const getallposts = () => ({ type: types.API_GET_POST_INITIATE })

export const deletepostInitiate = (payload) => ({ type: types.API_DELETE_POST_INITIATE, payload });


// export const deletepostSuccess = (payload) => ({ type: types.API_DELETE_POST_SUCCESS, payload });
// export const deletepostFailure = () => ({ type: types.API_DELETE_POST_FAILURE });



export const createPostInitiate = (payload) => ({ type: types.API_CREATE_POST_INITIATE, payload });

export const getdetail = () => ({ type: types.API_GET_DETAIL_INITIATE })


export const deleteFollowers = (payload) => ({ type: types.API_REMOVEFOLLWER_INITIATE, payload });
