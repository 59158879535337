import { combineReducers } from 'redux';
import { appState } from './appState';
import { authenticationReducer as authentication } from './authentication';
import { globalReducer } from './globalReducer';

const appReducer = combineReducers({
  appState,
  authentication,
  globalReducer
})

const rootReducer = (state, action) => {
  // if (action.type === types.API_ADMIN_LOGIN_START) {
  //   state = undefined
  // }
  return appReducer(state, action)
}

export default rootReducer;