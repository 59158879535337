import React, { useEffect } from 'react';

// Navigation
import { useHistory} from 'react-router-dom'
import 'antd/dist/antd.css'
import '../dashboard/dashboard.scss'
import * as Yup from 'yup';
import { Formik } from 'formik';
import clsx from "clsx";

// antd
import { Card, Table as BSTable, } from 'react-bootstrap';
// Mui Components
import {  Grid } from '@material-ui/core';
// Custom components
import DashboardLayout from '../../layouts/dashboardLayout'
import { InputField, Navbar, AppBar } from '../../customComponents'

import {  appConstants } from '../../themes/constants'
import { passwordRegex } from '../../themes/regex'
import { useStyles } from '../../styles/styles'
// Redux
import {  useDispatch } from 'react-redux'
import { changePasswordInitiate } from '../../store/actions'
// Global constants

export const ChangePassword = () => {
    // Hooks declarations
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch()


    const values = {
        oldPassword: "",
        password: "",
        confirmPasssword: ""
    };

    const validationSchema = Yup.object().shape({
        oldPassword: Yup.string().required("Please enter old password.")
            .test(
                "trim",
                "Please enter old password.",
                (value) => {

                    return value?.trim()?.length > 0
                }
            ),
        password:
            Yup.string().required("Please enter new password.")
                .test(
                    "trim",
                    "Please enter new password.",
                    (value) => {
                        return value?.trim()?.length > 0
                    }
                )
                .min(8, "New password should be at least 8 characters long.")
                .matches(passwordRegex, "New password must include 1 upper case letter, 1 lower case letter, 1 numeric value, 1 special character and no spaces.")
        ,
        confirmPasssword: Yup.string()
            .required("Please enter confirm new password.")
            .test(
                "trim",
                "Please enter confirm new password.",
                (value) => {

                    return value?.trim()?.length > 0
                }
            )
            .oneOf(
                [Yup.ref("password"), null],
                "Confirm new password must match with new password."
            )
    });

    useEffect(() => {
        document.title = appConstants.title;
    }, [])


    return (
        <DashboardLayout>
            <AppBar breadcrumbs={[{ route: '/change-password', name: "Change Password" }]} />
            <Navbar title="Change Password" />
            <Card className={classes.card}>
                {/* <Box m={3} /> */}
                <Grid container>
                    <Grid smUp={12} className={classes.tableContainerRow}>
                        <Formik
                            initialValues={values}
                            validationSchema={validationSchema}
                            onSubmit={(values) => {

                                dispatch(changePasswordInitiate({ oldPassword: values.oldPassword, newPassword: values.password }, history))
                            }}
                        >
                            {({
                                values,
                                errors,
                                handleBlur,
                                handleChange,
                                handleSubmit,
                                isSubmitting,
                                touched,
                            }) => (
                                <form onSubmit={handleSubmit}>
                                    <BSTable striped bordered>
                                        <tbody>
                                            <tr>
                                                <td className={classes.rowKey}>
                                                    Old Password
                                                </td>
                                                <td className={classes.rowValue}>
                                                    <div className={classes.inputWrapper}>
                                                        <InputField
                                                            placeholder="Old Password"
                                                            error={Boolean(touched.oldPassword && errors.oldPassword)}
                                                            helperText={touched.oldPassword && errors.oldPassword}
                                                            name="oldPassword"
                                                            // autoComplete='new-Password'
                                                            onBlur={handleBlur}
                                                            onChange={handleChange}
                                                            type="password"
                                                            value={values.oldPassword.trim()}
                                                            fullWidth
                                                            my
                                                        />
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className={classes.rowKey}>New Password</td>
                                                <td className={classes.rowValue}>
                                                    <div className={classes.inputWrapper}>
                                                        <InputField
                                                            placeholder="New Password"
                                                            name="password"
                                                            error={Boolean(touched.password && errors.password)}
                                                            helperText={touched.password && errors.password}
                                                            onBlur={handleBlur}
                                                            onChange={handleChange}
                                                            type="password"
                                                            value={values.password.trim()}
                                                        />
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className={classes.rowKey}>Confirm New Password</td>
                                                <td className={classes.rowValue}>
                                                    <div className={classes.inputWrapper}>
                                                        <InputField
                                                            placeholder="Confirm New Password"
                                                            name="confirmPasssword"
                                                            error={Boolean(touched.confirmPasssword && errors.confirmPasssword)}
                                                            helperText={touched.confirmPasssword && errors.confirmPasssword}
                                                            onBlur={handleBlur}
                                                            onChange={handleChange}
                                                            type="password"
                                                            value={values.confirmPasssword.trim()}
                                                        />
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className={classes.rowKey}></td>
                                                <td className={classes.rowValue}>
                                                    <button
                                                        type="submit"
                                                        className={clsx([classes.authButton, classes.filledButton])}
                                                    >
                                                        Update
                                                    </button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </BSTable>
                                </form>
                            )}
                        </Formik>
                    </Grid>
                </Grid>
            </Card>
        </DashboardLayout>
    );
}
