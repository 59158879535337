//React router navigation
import { Dashboard } from "../pages/dashboard/dashboard";
import { Redirect } from "react-router-dom";
import { ChangePassword } from "../pages/auth/changePassword";


const routes = [
  {
    path: "/",
    component: () => <Redirect to="/dashboard" />,
    exact: true,
  },
  {
    path: "/dashboard",
    component: Dashboard,
  },
  {
    path: "/Change-Password",
    component: ChangePassword,
  },
];

export default routes;
