import React from 'react';
import { Input } from 'antd';
import { useStyles } from './styles';

const NavbarSearch = (props) => {
  const classes = useStyles();
  return (
    <div>
      <Input className={classes.navbarInput} onChange={props.onChange} value={props.value} placeholder='Search' />
    </div>
  )
}

export default NavbarSearch

