import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { deleteFollowers, getallfollower } from '../../store/actions';
import { AppImages } from '../../themes/appImages';
import { Col, Row } from 'antd';
import Button from '../../customComponents/button';

export const Followers = () => {
    const dispatch = useDispatch();
    const followersData = useSelector((state) => state.authentication.followers);
    const deleteFollower = useSelector((state) => state.authentication.removefollower);

    useEffect(() => {
        dispatch(getallfollower());
    }, [dispatch, deleteFollower]);

    console.log("FollowersData:", followersData);

    const handleRemoveClick = (removeId) => {
        dispatch(deleteFollowers({ user_id: removeId }));
        console.log("uuuqqq", handleRemoveClick, removeId);
    };

    useEffect(() => {
        if (deleteFollower === 'success') {
            console.log('Follower removed successfully');
            dispatch(getallfollower());
        } else if (deleteFollower === 'failure') {
            console.error('Failed to remove follower');
        }
    }, [deleteFollower, dispatch]);

    return (
        <div style={{ paddingTop: 16 }}>
            <Row gutter={[12, 12]}>
                {followersData.map((follower, index) => (
                    <Col key={index.toString()} xs={24} sm={12} lg={8}>
                        <div style={{
                            backgroundColor: '#f5f5f5',
                            height: 120,
                            borderRadius: 8,
                            boxShadow: '0 0 25px rgba(0, 0, 0, 0.25)',
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            alignItems: 'center',
                            padding: 16,
                            gap: 8
                        }}>
                            <img src={follower.user.profileImage || AppImages.logo} style={{
                                borderRadius: 35,
                                width: 70,
                                height: 70,
                                objectFit: 'cover'
                            }} alt={`follower-${follower?.user.id}`} />
                            <div style={{
                                display: 'flex',
                                flexDirection: 'column',
                                width: '100%'
                            }}>
                                <span>{follower?.user.name}</span>
                                <Button title="Remove" onClick={() => handleRemoveClick(follower?.user?.id)} />
                            </div>
                        </div>
                    </Col>
                ))}
            </Row>
        </div>
    );
}
