import React, { useEffect } from 'react';
import 'antd/dist/antd.css';
import './dashboard.scss';
// import { makeStyles } from '@material-ui/core/styles';
import { Col, Row } from 'antd';
import Button from '../../customComponents/button';
import { useDispatch, useSelector } from 'react-redux';
import { deletepostInitiate, getallposts } from '../../store/actions';

// const useStyles = makeStyles((theme) => ({
//     card: {
//         top: -40,
//         position: 'relative',
//         width: '100%',
//         boxShadow: '0 1px 4px 0 rgba(0, 0, 0, 0.14)',
//         borderRadius: 3,
//         backgroundColor: '#ffffff',
//         marginBottom: 50,
//     },
// }));

const EXCHANGE_URLS_IMAGE = "https://api-v1.phanomprofessionals.com/uploads";

export const Posts = () => {
    const dispatch = useDispatch();
    const postdata = useSelector((state) => state.authentication.posts);
    const deletePostStatus = useSelector((state) => state.authentication.deletePostStatus);

  console.log("uuu",deletePostStatus)
    useEffect(() => {
        dispatch(getallposts());
    }, [dispatch, deletePostStatus]);

    const handleRemoveClick = (postId) => {
        dispatch(deletepostInitiate({ userId: postId, is_deleted: 1 }));
        // console.log("uuuqqq",handleRemoveClick, postId)
    };

    useEffect(() => {
        if (deletePostStatus === 'success') {
            console.log('Post deleted successfully');
        } else if (deletePostStatus === 'failure') {
            console.error('Failed to delete post');
        }
    }, [deletePostStatus]);

    return (
        <div style={{ paddingTop: 16 }}>
            <Row gutter={[24, 12]}>
                {postdata.map((item, index) => (
                    <Col key={index.toString()} xs={24} sm={12} lg={8}>
                        <div
                            style={{
                                backgroundColor: '#f5f5f5',
                                borderRadius: 8,
                                boxShadow: '0 0 25px rgba(0, 0, 0, 0.25)',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                padding: 16,
                                gap: 8,
                            }}
                        >
                            <img
                                src={`${EXCHANGE_URLS_IMAGE}/${item.media}`}
                                style={{
                                    borderRadius: 35,
                                    width: 100,
                                    height: 100,
                                    objectFit: 'cover',
                                }}
                                alt={`item-${item?.id}`}
                            />
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    width: '100%',
                                }}
                            >
                                <span>{item?.titles}</span>
                                <Button title="Edit" />

                                <Button title="Remove" onClick={() => handleRemoveClick(item.id)} />
                            </div>
                        </div>
                    </Col>
                ))}
            </Row>
        </div>
    );
};
