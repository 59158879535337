import React, { useEffect } from 'react';
import clsx from 'clsx';
import 'antd/dist/antd.css';
import { useStyles } from '../styles';
import './dashboardLayout.scss';
import withWidth, { isWidthDown, isWidthUp } from '@material-ui/core/withWidth';
import { Drawer, CssBaseline, Typography, Divider } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { getdetail, handleNavigationStateAction, handleSidebarDrawerToggleStateAction } from '../../store/actions';
import PhoneIcon from '@mui/icons-material/Phone';
import DescriptionIcon from '@mui/icons-material/Description';
import MailIcon from '@mui/icons-material/Mail';
import AppBar from '../../customComponents/appBar';

const DashboardLayout = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { appState } = useSelector(state => state);
  const { sidebarDrawerToggleState } = appState;


  const detaildata = useSelector((state) => state.authentication.detail);

  useEffect(() => {
      dispatch(getdetail());
  }, [dispatch]);

  console.log("detaildata:", detaildata); 
  useEffect(() => {
    if (isWidthUp('md', props.width)) {
      dispatch(handleSidebarDrawerToggleStateAction(true))
    } else if (isWidthDown('md', props.width)) {
      dispatch(handleSidebarDrawerToggleStateAction(false))
    }
  }, [props.width])

  useEffect(() => {
    const nestedRouteIndex = localStorage.getItem("sidebarNestedRouteIndex");
    const routeIndex = localStorage.getItem("sidebarRouteIndex");
    if (nestedRouteIndex) {
      dispatch(handleNavigationStateAction(2, false))
      dispatch(handleNavigationStateAction(nestedRouteIndex, true))
    } else {
      dispatch(handleNavigationStateAction(routeIndex || 0, false))
    }
  }, [])
  const EXCHANGE_URLS_IMAGE = "https://api-v1.phanomprofessionals.com/uploads";

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Drawer
        className={classes.drawer}
        variant={!isWidthUp('md', props.width) ? "temporary" : "persistent"}
        open={sidebarDrawerToggleState}
        classes={{
          paper: classes.drawerPaper,
        }}
        anchor={!isWidthUp('md', props.width) ? "right" : "left"}
        onClose={() => sidebarDrawerToggleState ? dispatch(handleSidebarDrawerToggleStateAction(false)) : dispatch(handleSidebarDrawerToggleStateAction(true))}
        ModalProps={{
          keepMounted: true // Better open performance on mobile.
        }}
      >
      

        <div className={classes.drawerLogo}>
          <img   src={`${EXCHANGE_URLS_IMAGE}/${detaildata?.image}`} alt="logo-image" className={classes.logo} />
          <Typography variant="h6" className={classes.title} classes={{ root: classes.typographyRoot }} id="title">{detaildata?.creator_name}</Typography>
        </div>

        <Divider variant="middle" />
        <div className={classes.rowContainer}>
          <PhoneIcon htmlColor='#bfbfbf' className={classes.profileDetialsIcons} />
          <Typography variant="h7" className={classes.title} classes={{ root: classes.typographyRoot }} id="title">{detaildata?.phone_number}</Typography>
        </div>
        <div className={classes.rowContainer}>
          <MailIcon htmlColor='#bfbfbf' className={classes.profileDetialsIcons} />
          <Typography variant="h7" className={classes.title} classes={{ root: classes.typographyRoot }} id="title">{detaildata?.email}</Typography>
        </div>
        <div className={classes.rowContainer}>
          <DescriptionIcon htmlColor='#bfbfbf' className={classes.profileDetialsIcons} />
          <Typography variant="h7" className={classes.title} classes={{ root: classes.typographyRoot }} id="title">
            {detaildata?.bio}
          </Typography>
        </div>
        <div className="sidebarBackground" style={{ zIndex: -7, }} />
      </Drawer>
      <main className={clsx(classes.content, {
        [classes.contentShift]: sidebarDrawerToggleState,
      })}>
        <>
          <AppBar />
          {props.children}
        </>
      </main>
    </div>
  );
}

export default withWidth()(DashboardLayout)
