import React from 'react';
import 'antd/dist/antd.css';
import './dashboard.scss';
import DashboardLayout from '../../layouts/dashboardLayout';
import { Tabs } from 'antd';
import { Followers } from './followers';
import { Posts } from './posts';



export const Dashboard = () => {
   
    return (
        <>
            <DashboardLayout>
                <Tabs defaultActiveKey="1" items={[
                    {
                        key: '1',
                        label: 'Followers',
                        children: <Followers
                         />,
                    },
                    {
                        key: '2',
                        label: 'Posts',
                        children: <Posts />,
                    },
                ]} />
            </DashboardLayout>
            
        </>
    );
}
