import React from "react";
import styled from "styled-components";
import * as Yup from "yup";
import axios from "axios";
import { useFormik } from "formik";
import { useHistory } from "react-router-dom";
import { Grid, TextField, Typography } from "@material-ui/core";
import { AppImages } from "../../themes/appImages";
import { useStyles } from "../../styles/styles";
import MainLayout from "../../layouts/mainLayout";
import clsx from "clsx";

const Register = () => {
  const history = useHistory();
  const classes = useStyles();

  const formik = useFormik({
    initialValues: {
      creator_name: "",
      bio: "",
      image: null,
      email: "",   
      phone_number: "",
      bank_number: "",
      password: "",
    },
    validationSchema: Yup.object({
      creator_name: Yup.string().required("creator name is required"),
      bio: Yup.string().required("bio are required"),
      image: Yup.mixed().required("image file is required"),
      email: Yup.string().required("email are required"),
      phone_number: Yup.number().required("phone number are required"),
      bank_number: Yup.string().required("password are required"),
      password: Yup.string().required("password are required"),
    }),
    onSubmit: async (values) => {
      const formData = new FormData();
      for (const key in values) {
        formData.append(key, values[key]);
      }
      try {
        const response = await axios.post(
          "https://api-v1.phanomprofessionals.com/api/creator/registercreator",
          formData
        );
    
        if (response.status === 201) {
          const responseData = response.data;
          console.log("register added successfully:", responseData);
          formik.resetForm();
    
          history.push("/login");
        } else {
          const errorData = response.data;
          console.error("Error adding post:", errorData);
    
         
        }
      } catch (error) {
        console.error("Unexpected error:", error);
      }
    },
  });

  return (
    
    <MainLayout>
      <Grid
        container
        justify="center"
        alignItems="center"
        direction="column"
        className="m-4"
        >
        <Root>
        <img src={AppImages.logo} alt="logo-image" className={classes.logo} />
        <Typography variant="h5">Register</Typography>
        <div>
          <form onSubmit={formik.handleSubmit}>
            <TextField
              placeholder="Creator Name"
              id="creator_name"
              name="creator_name"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.creator_name}
              error={
                formik.touched.creator_name &&
                Boolean(formik.errors.creator_name)
              }
              helperText={
                formik.touched.creator_name && formik.errors.creator_name
              }
            />

            <TextField
              placeholder="Bio Add"
              id="bio"
              name="bio"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.bio}
              error={formik.touched.bio && Boolean(formik.errors.bio)}
              helperText={formik.touched.bio && formik.errors.bio}
            />
            <div className="h111">

            <label htmlFor="image"></label>
            <input
              type="file"
              id="image"
              name="image"
              onChange={(event) =>
                formik.setFieldValue("image", event.target.files[0])
              }
            />
            </div>
            {formik.errors.image && <div>{formik.errors.image}</div>}

            <TextField
              placeholder="email"
              id="email"
              name="email"
              type="email"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />

            <TextField
              placeholder="Phone Number"
              id="phone_number"
              name="phone_number"
              type="number"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.phone_number}
              error={
                formik.touched.phone_number &&
                Boolean(formik.errors.phone_number)
              }
              helperText={
                formik.touched.phone_number && formik.errors.phone_number
              }
            />

            <TextField
              placeholder="Bank Number"
              id="bank_number"
              name="bank_number"
              type="number"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.bank_number}
              error={
                formik.touched.bank_number && Boolean(formik.errors.bank_number)
              }
              helperText={
                formik.touched.bank_number && formik.errors.bank_number
              }
            />

            <TextField
              placeholder="password"
              id="password"
              name="password"
              type="password"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.password}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
            />

            <div className="child">
              <button type="submit" disabled={formik.isSubmitting}>
                Add
              </button>
          <Grid container justify="center">
                  <Typography
                    variant="body1"
                    display="block"
                    className={clsx([classes.authButton, classes.linkButton])}
                    onClick={() => history.push("/login")}
                  >
                    Login here?
                  </Typography>
                </Grid>
            </div>
          </form>
        </div>
    </Root>
      </Grid>
    </MainLayout>
  );
};

export default Register;

const Root = styled.section`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;  // Add this line to center the content horizontally
  align-items: center;
width: 100%;

img{
  width: 100px;
    height: 100px;
}
  div {
    display: flex;
    /* padding: 10px 20px; */
    flex-direction: column;
    background-color: white;
    align-items: center;

    margin: 3px;
   input{
margin: 4;
width: 270px;
   }
   .h111{
    margin-left: 8px;
    width: 95%;
    border: 1px solid #d2d2d2;  
   label {
    width: 100px;
   }
  }

  .child{
    justify-content: center;
    display: flex;
    align-items: center;

    button{
      background-color: #ffad00;
    font-size: 19px;
    width: 31%;
    border: 1px solid #d2d2d2;
    cursor: pointer;
    border-radius: 9px;
    height: 40px;
    color: white;
    font-family: -webkit-body;
    font-weight: 900;
    }
  }
  }`;