import types from "../types/index";
const initialState = {
    isLoading: false,
    resetPasswordLinkFetched: false,
    resetPasswordLinkExpired: false,
    followers :[],
    posts : [],
    deletePostStatus: null, 
    createPostStatus: null,
    detail : [],
    removefollower : []


};

export const authenticationReducer = (state = initialState, action) => {

    switch (action.type) {
        // Login
        case types.API_LOGIN_INITIATE: return { ...state, isLoading: true };
        case types.API_LOGIN_SUCCESS: return { ...state, isLoading: false };

        // Logout
        case types.API_LOGOUT_INITIATE: return { ...state, isLoading: true };
        case types.API_LOGOUT_SUCCESS: return { ...state, isLoading: false };

        // Forgot Password
        case types.API_FORGOT_PASSWORD_INITIATE: return { ...state, isLoading: true };
        case types.API_FORGOT_PASSWORD_SUCCESS: return { ...state, isLoading: false };

        // Change Password
        case types.API_CHANGE_PASSWORD_INITIATE: return { ...state, isLoading: true };
        case types.API_CHANGE_PASSWORD_SUCCESS: return { ...state, isLoading: false };

        // // Check Reset Password
        case types.API_RESET_PASSWORD_LINK_INITIATE: return { ...state, isLoading: true };
        case types.API_RESET_PASSWORD_LINK_SUCCESS: return { ...state, isLoading: false, resetPasswordLinkFetched: true };

        // // Reset Password
        case types.API_RESET_PASSWORD_INITIATE: return { ...state, isLoading: true };
        case types.API_RESET_PASSWORD_SUCCESS: return { ...state, isLoading: false, resetPasswordLinkFetched: false };

        case types.API_AUTHENTICATION_FAILED: return { ...state, isLoading: false, resetPasswordLinkFetched: false, resetPasswordLinkExpired: true };

        case types.API_GET_FOLLOWERS_SUCCESS: return { ...state, followers : action.payload };

        // get post
        case types.API_GET_POST_SUCCESS: return { ...state, posts : action.payload };

        // delete post
        case types.API_DELETE_POST_SUCCESS:  return { ...state, deletePostStatus: action.payload };


        case types.API_CREATE_POST_INITIATE: return { ...state, createPostStatus: null };
        case types.API_CREATE_POST_SUCCESS: return { ...state, createPostStatus: action.payload };
        case types.API_CREATE_POST_FAILURE: return { ...state, createPostStatus: 'failure' };
      
        case types.API_GET_DETAIL_SUCCESS: return { ...state, detail : action.payload };



/// remove follower
case types.API_REMOVEFOLLWER_SUCCESS:  return { ...state, removefollower: action.payload };


        default: return { ...state };
    }
};
